import Vue from "vue";
import Router from "vue-router";
import home from "@/views/home";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/page0",
    },
    {
      path: "",
      name: "home",
      component: home,
      children: [
        {
          path: "/page0",
          name: "page0",
          component: () => import("@/views/page0"),
        },
        // {
        //   path: "/page1",
        //   name: "page1",
        //   component: () => import("@/views/page1"),
        // },
        // {
        //   path: "/page2",
        //   name: "page2",
        //   component: () => import("@/views/page2"),
        // },
        // {
        //   path: "/page3",
        //   name: "page3",
        //   component: () => import("@/views/page3"),
        // },
      ],
    },
  ],
  mode: 'hash'
});
export default router;
